// https://ckeditor.com/docs/ckeditor5/latest/examples/builds-custom/full-featured-editor.html

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import AutoImage from '@ckeditor/ckeditor5-image/src/autoimage';
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
// import Code from '@ckeditor/ckeditor5-basic-styles/src/code';
// import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock';
// import EasyImage from '@ckeditor/ckeditor5-easy-image/src/easyimage';
// import ExportPdf from '@ckeditor/ckeditor5-export-pdf/src/exportpdf';
// import ExportWord from '@ckeditor/ckeditor5-export-word/src/exportword';
import Enter from "@ckeditor/ckeditor5-enter/src/enter";
import FindAndReplace from '@ckeditor/ckeditor5-find-and-replace/src/findandreplace';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import ImageEditing from '@ckeditor/ckeditor5-image/src/image/imageediting';
import ImageBlockEditing from '@ckeditor/ckeditor5-image/src/image/imageblockediting';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageBlock from '@ckeditor/ckeditor5-image/src/imageblock';
import ImageInline from '@ckeditor/ckeditor5-image/src/imageinline';
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';
import List from '@ckeditor/ckeditor5-list/src/list';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import Mention from '@ckeditor/ckeditor5-mention/src/mention';
// import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting';
// import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters';
// import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCaption from '@ckeditor/ckeditor5-table/src/tablecaption';
// import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation';
// import TextPartLanguage from '@ckeditor/ckeditor5-language/src/textpartlanguage';
// import TodoList from '@ckeditor/ckeditor5-list/src/todolist';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount';
import Preview from "./preview";
import Video from "./video/video";
import CustomField from "./customField";
import MentionCustomization from "./MentionCustomization";
import {ShiftEnter} from "@ckeditor/ckeditor5-enter";
// import WProofreader from '@webspellchecker/wproofreader-ckeditor5/src/wproofreader';
// import CloudServices from '@ckeditor/ckeditor5-cloud-services/src/cloudservices';

// ArticlePluginSet, Underline, Strikethrough, Superscript, Subscript, Code, RemoveFormat,
// FindAndReplace, Font, Highlight,
// CodeBlock, TodoList, ListProperties, TableProperties, TableCellProperties, TableCaption,
// EasyImage, ImageResize, LinkImage, AutoImage, HtmlEmbed,
// AutoLink, Mention, TextTransformation,
// Alignment, IndentBlock,
// PasteFromOffice, PageBreak, HorizontalLine,
// SpecialCharacters, SpecialCharactersEssentials, WordCount, WProofreader,
// ImageUpload, CloudServices, TextPartLanguage, SourceEditing,
// ExportPdf, ExportWord

// 'undo', 'redo',
// '|',
// 'exportPdf', 'exportWord',
// '|',
// 'wproofreader', 'findAndReplace', 'selectAll',
// '|',
// 'heading',
// '|',
// 'removeFormat', 'bold', 'italic', 'strikethrough', 'underline', 'code', 'subscript', 'superscript',
// '|',
// 'specialCharacters', 'horizontalLine', 'pageBreak',
// '|',
// '-',
// 'highlight', 'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor',
// '|',
// 'link', 'blockQuote', 'insertTable', 'uploadImage', 'mediaEmbed', 'codeBlock', 'htmlEmbed',
// '|',
// 'bulletedList', 'numberedList', 'todoList',
// '|',
// 'outdent', 'indent', 'alignment',
// '|',
// 'textPartLanguage',
// '|',
// 'sourceEditing'

// Plugins to include in the build.
ClassicEditor.builtinPlugins = [
  BlockQuote, Bold, Italic, Heading, Table,
  Underline, Strikethrough, Superscript, Subscript, RemoveFormat,
  FindAndReplace, Font, Highlight,
  List, TableToolbar, TableProperties, TableCellProperties, TableCaption,
  ImageBlock, ImageInline,
  ImageCaption, ImageStyle, ImageInsert,
  ImageBlockEditing, ImageEditing, ImageResize, LinkImage, AutoImage, HtmlEmbed,
  ImageToolbar,
  AutoLink, Mention,
  Alignment, Indent, IndentBlock,
  PasteFromOffice, HorizontalLine,
  WordCount,
  ImageUpload, SourceEditing,
  Preview, CustomField, Video, MentionCustomization, Enter, ShiftEnter
];

// Editor configuration.
ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      'undo', 'redo',
      '|',
      'findAndReplace',
      '|',
      'heading',
      '|',
      'removeFormat', 'bold', 'italic', 'strikethrough', 'underline',
      '|',
      'horizontalLine',
      '|',
      'bulletedList', 'numberedList',
      '|',
      'outdent', 'indent', 'alignment', 'sourceEditing',
      '-',
      'highlight', 'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor',
      '|',
      'link', 'blockQuote', 'insertTable', 'uploadImage', 'video', 'htmlEmbed', 'preview', 'customField'
    ],
    shouldNotGroupWhenFull: true
  },
  // sample custom fieldss
  // customField: {
  //   fields: [{
  //     key: '{{test}}',
  //     label: 'Test'
  //   }, {
  //     key: '{{another}}',
  //     label: 'Another Test Very Long To Stretch'
  //   }]
  // },
  image: {
    toolbar: [
      "imageStyle:inline",
      "imageStyle:block",
      "imageStyle:side",
      "|",
      "linkImage",
      "|",
      "resizeImage:original",
      "resizeImage:50",
      "resizeImage:75",
      "resizeImage:100",
    ],
    resizeOptions: [
      {
        name: 'resizeImage:original',
        value: null,
        icon: 'original'
      },
      {
        name: 'resizeImage:50',
        value: '50',
        icon: 'medium'
      },
      {
        name: 'resizeImage:75',
        value: '75',
        icon: 'large'
      },
      {
        name: 'resizeImage:100',
        value: '100',
        icon: 'original'
      }
    ],
  },
  // This value must be kept in sync with the language defined in webpack.config.js.
  language: 'en'
};

window.ClassicEditor = ClassicEditor;
