import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import {
  ButtonView,
  ContextualBalloon,
  clickOutsideHandler
} from '@ckeditor/ckeditor5-ui';
import './video.css';
import converters from './converters';

import videoIcon from './video.svg';
import FormView from "./FormView";

const VIDEO = "VIDEO";

export default class Video extends Plugin {
  static get requires() {
    return [ContextualBalloon];
  }

  static get pluginName() {
    return 'Video';
  }

  init() {
    const editor = this.editor;

    this._balloon = this.editor.plugins.get(ContextualBalloon);
    this.formView = this._createFormView();

    // Add bold button to feature components.
    editor.ui.componentFactory.add(VIDEO, locale => {
      const button = new ButtonView(locale);

      button.set({
        label: 'Video',
        icon: videoIcon,
        tooltip: true
      });

      this.listenTo(button, 'execute', () => {
        this._showUI();
      });
      return button;
    });
  }

  _createFormView() {
    const editor = this.editor;
    const formView = new FormView(editor.locale);

    this.listenTo(formView, 'submit', () => {
      const url = formView.urlInputView.fieldView.element.value;
      let inserted = false;
      for (let converter of converters) {
        if (converter.regex.test(url)) {
          const content = converter.convert(url, converter.regex);
          const model = editor.model;
          model.change(writer => {
            const htmlEmbedElement = writer.createElement('rawHtml');
            model.insertObject(htmlEmbedElement, null, null, {setSelection: 'on'});
            writer.setAttribute('value', content, htmlEmbedElement);
          });
          inserted = true;
          break;
        }
      }
      if (inserted) {
        this._hideUI();
      } else {
        alert('This url is not supported at the moment.');
      }
    });

    this.listenTo(formView, 'cancel', () => {
      this._hideUI();
    });

    // Hide the form view when clicking outside the balloon.
    clickOutsideHandler({
      emitter: formView,
      activator: () => this._balloon.visibleView === formView,
      contextElements: [this._balloon.view.element],
      callback: () => this._hideUI()
    });

    return formView;
  }

  _getBalloonPositionData() {
    const view = this.editor.editing.view;
    const viewDocument = view.document;

    // Set a target position by converting view selection range to DOM.
    const target = () => view.domConverter.viewRangeToDom(
      viewDocument.selection.getFirstRange()
    );

    return {
      target
    };
  }

  _showUI() {
    this._balloon.add({
      view: this.formView,
      position: this._getBalloonPositionData()
    });

    this.formView.focus();
  }

  _hideUI() {
    this.formView.urlInputView.fieldView.value = '';
    this.formView.element.reset();
    this._balloon.remove(this.formView);
    this.editor.editing.view.focus();
  }
}
