import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

import previewIcon from './preview.svg';

const PREVIEW = 'PREVIEW';

export default class Preview extends Plugin {
  static get pluginName() {
    return 'Preview';
  }

  init() {
    const editor = this.editor;
    let popup = null;
    const {width, height, beforeContent, afterContent} = {...{
      width: 320,
      height: 600,
      beforeContent: '',
      afterContent: '',
    },...(editor.config.get( 'preview' ) || {})};

    // Listen to editor changes
    editor.model.document.on('change:data', () => {
      if (popup?.document?.body) {
        popup.document.body.innerHTML = "";
        popup.document.write(beforeContent + editor.getData() + afterContent);
      }
    });

    editor.on('change:state', (e, event, state) =>{
      if (state === 'destroyed' && popup) {
        popup.close();
        popup = null;
      }
    })

    // Add bold button to feature components.
    editor.ui.componentFactory.add( PREVIEW, locale => {
      const view = new ButtonView( locale );

      view.set( {
        label: 'Preview',
        icon: previewIcon,
        tooltip: true
      } );

      this.listenTo( view, 'execute', () => {
        if (popup || (popup && !popup.closed)) {
          popup.focus();
        } else {
          popup = window.open(
            "",
            "_blank",
            `popup,width=${width},height=${height}`,
          );

          if (popup) {
            // sometimes it set to null and causing issues after
            popup.document.title = "Preview";
            popup.document.write(beforeContent + editor.getData() + afterContent);
            popup.addEventListener("beforeunload", () => {
              popup = null;
            });
          }
        }
      } );

      window.onbeforeunload = () => {
        if (popup) popup.close();
      }

      return view;
    } );
  }
}
