const converters = [{
  // Youtube ;)
  // https://stackoverflow.com/a/71006865
  regex: /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/,
  convert: (input, regex) => {
    const matches = input.match(regex);
    if (matches.length > 3) {
      return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${matches[3]}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
    } else {
      return '<b>Cannot insert video url</b>';
    }
  }
}, {
  // Vimeo ;)
  // https://stackoverflow.com/a/15885221
  regex: /^(http\:\/\/|https\:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/,
  convert: (input, regex) => {
    const matches = input.match(regex);
    if (matches.length > 4) {
      return `<iframe src="https://player.vimeo.com/video/${matches[4]}?title=0&byline=0&portrait=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`;
    } else {
      return '<b>Cannot insert video url</b>';
    }
  }
}, {
  // any video
  // https://samplelib.com/lib/preview/mp4/sample-5s.mp4
  regex: /^(.*?)\.mp4$/,
  convert: (input, regex) => {
    const matches = input.match(regex);
    if (matches.length > 1) {
      return `<video src="${matches[1]}" controls></video>`;
    } else {
      return '<b>Cannot insert video url</b>';
    }
  }
}]

export default converters;
