import {
  ButtonView,
  createLabeledInputText,
  LabeledFieldView,
  LabelView,
  submitHandler,
  View
} from "@ckeditor/ckeditor5-ui";
import {icons} from "@ckeditor/ckeditor5-core";

export default class FormView extends View {
  constructor(locale) {
    super(locale);

    this.urlInputView = this._createInput('Video URL');

    this.saveButtonView = this._createButton('Save', icons.check, 'ck-button-save');
    this.saveButtonView.type = 'submit';
    this.cancelButtonView = this._createButton('Cancel', icons.cancel, 'ck-button-cancel');
    this.cancelButtonView.delegate('execute').to(this, 'cancel');

    this.childViews = this.createCollection([
      this.urlInputView,
      this.saveButtonView,
      this.cancelButtonView
    ]);

    this.setTemplate({
      tag: 'form',
      attributes: {
        class: ['ck', 'ck-video-form'],
        tabIndex: '-1'
      },
      children: this.childViews
    });

  }

  _createInput(label) {
    const labeledInput = new LabeledFieldView(this.locale, createLabeledInputText);
    labeledInput.label = label;
    return labeledInput;
  }

  _createButton(label, icon, className) {
    const button = new ButtonView(this.locale);

    button.set({
      label,
      icon,
      tooltip: true,
      class: className
    });

    return button;
  }

  render() {
    super.render();
    submitHandler({
      view: this
    });
  }

  focus() {
    this.childViews.first.focus();
  }
}
